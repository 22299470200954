import React from "react"
import SEO from "../components/SEO"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import HeroContainer from "../components/heroContainer"
import RenderNewsCards from "../components/news/RenderNewsCards"
import PageTitle from "../components/PageTitle"
import { sanityConfig } from "../sanityConfig"
import { getFluidGatsbyImage } from "gatsby-source-sanity"
import BackgroundContainer from "../components/containers/BackgroundContainer"

const News = ({ data }) => {
  const { sanityNewsPage } = data
  const newsPosts = data.newsPosts
  const defaultHeroFluid = data.heroImage.childImageSharp.fluid
  const secondFluid = data.secondBannerImage.childImageSharp.fluid

  const fluidBanner =
    getFluidGatsbyImage(
      sanityNewsPage?._rawHeaderImage?.asset?.id,
      { maxWidth: 1200 },
      sanityConfig
    ) || defaultHeroFluid

  const heroProps = {
    fluid: fluidBanner,
    gradient: true,
  }

  return (
    <Layout>
      <SEO title="News" />
      <HeroContainer props={heroProps} />
      <BackgroundContainer>
        <PageTitle text="News" />
        <div className="my-20 mx-5 grid justify-center">
          <RenderNewsCards
            newsCardsData={newsPosts}
            className="bg-white  bg-opacity-100 shadow-md"
          />
        </div>
      </BackgroundContainer>
    </Layout>
  )
}

export default News

export const query = graphql`
  query {
    sanityNewsPage {
      _rawHeaderImage(resolveReferences: { maxDepth: 10 })
      title
    }
    heroImage: file(relativePath: { eq: "home_hero.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    secondBannerImage: file(relativePath: { eq: "second_banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    newsPosts: allSanityBlogPost {
      edges {
        node {
          ...NewsCard
        }
      }
    }
  }
`
